import React, { useState, useEffect } from 'react'
import Navbar from './Navbar'
import Elements from './Elements'
import logodark from "../Contents/images/logoblack.png";
import logolight from "../Contents/images/logowhite2.png";
import Footer from './Footer'
import Courses from './Courses'
import Partners from './Partners'
import Contact from './Contact'
import html from '../Contents/images/html.png'
import css from '../Contents/images/css.png'
import react from '../Contents/images/react.png'
import vue from '../Contents/images/vue.png'
import angular from '../Contents/images/angular.png'
import python from '../Contents/images/python.png'
import django from '../Contents/images/django.png'
import javascript from '../Contents/images/js.png'
import nodejs from '../Contents/images/nodejs.png'
import typescript from '../Contents/images/typescript.png'
import vite from '../Contents/images/vite.png'
import electron from '../Contents/images/electron.png'
import figma from '../Contents/images/figma.png'

const Main = () => {

  const [loading, setLoading] = useState(true)

  fetch("https://reqres.in/api/users?delay=5")
  .then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  })
  .then((data) => {
    console.log(data)
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
  })
  .finally(() => {
    setLoading(false);
  });

  const [cursorStyle1, setCursorStyle1] = useState('cursor-default1');
  const [cursorStyle2, setCursorStyle2] = useState('cursor-default2');

  const handleLinkEnter = () => {
    setCursorStyle1('cursor-hover1');
    setCursorStyle2('cursor-hover2');
  };

  const handleLinkLeave = () => {
    setCursorStyle1('cursor-default1');
    setCursorStyle2('cursor-default2');
  };

  useEffect(() => {
    const links = document.querySelectorAll('a');

    links.forEach((link) => {
      link.addEventListener('mouseenter', handleLinkEnter);
      link.addEventListener('mouseleave', handleLinkLeave);
    });

    // Cleanup event listeners when the component unmounts
    return () => {
      links.forEach((link) => {
        link.removeEventListener('mouseenter', handleLinkEnter);
        link.removeEventListener('mouseleave', handleLinkLeave);
      });
    };
  }, []);

  function closeRightSide() {
    setTimeout(() => {
      let side = document.querySelector(".right-swipe-side")
      side.classList.remove("open-right-side")
      let bg = document.querySelector(".bg-right-side")
      bg.classList.remove("bg-come")
      document.querySelector('.Navbar').classList.remove('nav-none')
    }, 150);
  }
  function closeMob() {
    let menus = document.querySelector(".mob-menu-items")
    setTimeout(() => {
      menus.classList.remove("open-mob-menu")
    }, 400);
  }
  window.addEventListener("load", closeMob);

  document.addEventListener('mousemove', function(e) {
    let cursor1 = document.querySelector('.cursor1')
    let cursor2 = document.querySelector('.cursor2')
    cursor1.style.cssText = cursor2.style.cssText = "left: " + e.clientX + "px; top: " + e.clientY + "px;"
  })

  

  return (

    <div className='Main' id='main'>
      <div style={loading === true ? {display: "block"} : {display: "none"}} className="loader">
        <div className="cen-load">
          <svg class="pencil" viewBox="0 0 200 200" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="pencil-eraser">
          <rect rx="5" ry="5" width="30" height="30"></rect>
        </clipPath>
      </defs>
      <circle class="pencil__stroke" r="70" fill="none" stroke="currentColor" stroke-width="2" stroke-dasharray="439.82 439.82" stroke-dashoffset="439.82" stroke-linecap="round" transform="rotate(-113,100,100)" />
      <g class="pencil__rotate" transform="translate(100,100)">
        <g fill="none">
          <circle class="pencil__body1" r="64" stroke="hsl(223,90%,50%)" stroke-width="30" stroke-dasharray="402.12 402.12" stroke-dashoffset="402" transform="rotate(-90)" />
          <circle class="pencil__body2" r="74" stroke="hsl(223,90%,60%)" stroke-width="10" stroke-dasharray="464.96 464.96" stroke-dashoffset="465" transform="rotate(-90)" />
          <circle class="pencil__body3" r="54" stroke="hsl(223,90%,40%)" stroke-width="10" stroke-dasharray="339.29 339.29" stroke-dashoffset="339" transform="rotate(-90)" />
        </g>
        <g class="pencil__eraser" transform="rotate(-90) translate(49,0)">
          <g class="pencil__eraser-skew">
            <rect fill="hsl(223,90%,70%)" rx="5" ry="5" width="30" height="30" />
            <rect fill="hsl(223,90%,60%)" width="5" height="30" clip-path="url(#pencil-eraser)" />
            <rect fill="hsl(223,10%,90%)" width="30" height="20" />
            <rect fill="hsl(223,10%,70%)" width="15" height="20" />
            <rect fill="hsl(223,10%,80%)" width="5" height="20" />
            <rect fill="hsla(223,10%,10%,0.2)" y="6" width="30" height="2" />
            <rect fill="hsla(223,10%,10%,0.2)" y="13" width="30" height="2" />
          </g>
        </g>
        <g class="pencil__point" transform="rotate(-90) translate(49,-30)">
          <polygon fill="hsl(33,90%,70%)" points="15 0,30 30,0 30" />
          <polygon fill="hsl(33,90%,50%)" points="15 0,6 30,0 30" />
          <polygon fill="hsl(223,10%,10%)" points="15 0,20 10,10 10" />
        </g>
      </g>
          </svg>
        </div>
      </div>
      <div className={`cursor1 ${cursorStyle1}`}></div>
      <div className={`cursor2 ${cursorStyle2}`}></div>
      <div className="shapes">
        <div className="shape1"></div>
        <div className="shape2"></div>
      </div>
      <div className="main-upper">
        <Navbar />
        <div className="main-sec" id='main-sec'>
          <div className="main-wrap">
            <div className="upper-texts">
              <img className='logo-dark' src={logodark} />
              <img className='logo-light' src={logolight} />
              <p>Ilm - bu har bir yaxshilikning boshi!</p>
            </div>
            <div className="frameworks">
              <ul>
                  <li><img src={html} /></li>
                  <li><img src={css} /></li>
                  <li><img src={javascript} /></li>
                  <li><img src={react} /></li>
                  <li><img src={electron} /></li>
                  <li><img src={vue} /></li>
                  <li><img src={typescript} /></li>
                  <li><img src={angular} /></li>
                  <li><img src={python} /></li>
                  <li><img src={django} /></li>
                  <li><img src={nodejs} /></li>
                  <li><img src={vite} /></li>
                  <li><img src={figma} /></li>
              </ul>
            </div>
          </div>
        </div>

        <Elements />
        <Courses />
        <Partners />
        <Contact />
        <Footer />
      </div>
    </div>
  )
}

export default Main

