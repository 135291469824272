import React from 'react'
import frontimg from '../Contents/images/front.png'
import backtimg from '../Contents/images/back.png'
import fullimg from '../Contents/images/full.png'
import seoimg from '../Contents/images/seo.png'
import other from '../Contents/images/other.png'
import cyberimg from '../Contents/images/cyber.png'
// import { Tilt } from 'react-tilt'
import { Link, NavLink } from 'react-router-dom'
// import { Link } from "react-scroll";


const Courses = () => {
    return (
        <div className="Courses" id='courses'>
            <div className="card card-title">
                <span>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                    <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' overflow='visible' height='100%' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='4' stroke-linecap='square' stroke-miterlimit='10'><polyline points='0,18 12,6 24,18 '/></svg>
                </span>
                <h1>Bizning kurslar</h1>
                <a href='#contact'  className='btn-i-primary-dark-hovered'>Kursga yozilish</a>
            </div>
            <div className="card">
                <div className="front">
                    <div className="imgbox">
                        <img src={frontimg}/>
                    </div>
                    <div className="title">
                        <h2>Foundation</h2>
                    </div>
                </div>
                <div className="swipe">
                    <NavLink to='/foundation' className="btn-i-primary-dark-hovered">Ko'rish</NavLink>
                </div>
            </div>
            <div className="card">
                <div className="front">
                    <div className="imgbox">
                        <img src={other}/>
                    </div>
                    <div className="title">
                        <h2>Python kursi</h2>
                    </div>
                </div>
                <div className="swipe">
                    <NavLink to='/pythoncourse' className="btn-i-primary-dark-hovered">Ko'rish</NavLink>
                </div>
            </div>
            <div className="card">
                <div className="front">
                    <div className="imgbox">
                        <img src={other}/>
                    </div>
                    <div className="title">
                        <h2>Java kursi</h2>
                    </div>
                </div>
                <div className="swipe">
                    <NavLink to="/javacourse" className="btn-i-primary-dark-hovered">Ko'rish</NavLink>
                </div>
            </div>
            <div className="card">
                <div className="front">
                    <div className="imgbox">
                        <img src={frontimg}/>
                    </div>
                    <div className="title">
                        <h2>Full Frontend</h2>
                    </div>
                </div>
                <div className="swipe">
                    <Link to='/frontend' className="btn-i-primary-dark-hovered">Ko'rish</Link>
                </div>
            </div>
            <div className="card">
                <div className="front">
                    <div className="imgbox">
                        <img src={backtimg}/>
                    </div>
                    <div className="title">
                        <h2>Full Backend</h2>
                    </div>
                </div>
                <div className="swipe">
                    <NavLink to="/backend" className="btn-i-primary-dark-hovered">Ko'rish</NavLink>
                </div>
            </div>
            <div className="card">
                <div className="front">
                    <div className="imgbox">
                        <img src={other}/>
                    </div>
                    <div className="title">
                        <h2>Kotlin kursi</h2>
                    </div>
                </div>
                <div className="swipe">
                    <NavLink to="/kotlincourse" className="btn-i-primary-dark-hovered">Ko'rish</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Courses;