import React, { useState, useEffect } from 'react'
import FrNavbar from './FrNavbar'
import Footer from './Footer'
import Contact from './Contact'
import Elements from './Elements'
import html from '../Contents/images/html.png'
import css from '../Contents/images/css.png'
import foundationset from '../Contents/images/foundationset.png'
import Courses from './Courses'

const Foundation = () => {
  const [loading, setLoading] = useState(true)

  fetch("https://reqres.in/api/users?delay=2")
  .then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  })
  .then((data) => {
    console.log(data)
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
  })
  .finally(() => {
    setLoading(false);
  });

    const [cursorStyle1, setCursorStyle1] = useState('cursor-default1');
  const [cursorStyle2, setCursorStyle2] = useState('cursor-default2');

  // Event handlers to change cursor styles
  const handleLinkEnter = () => {
    setCursorStyle1('cursor-hover1');
    setCursorStyle2('cursor-hover2');
  };

  const handleLinkLeave = () => {
    setCursorStyle1('cursor-default1');
    setCursorStyle2('cursor-default2');
  };

  useEffect(() => {
    const links = document.querySelectorAll('a', 'button');

    links.forEach((link) => {
      link.addEventListener('mouseenter', handleLinkEnter);
      link.addEventListener('mouseleave', handleLinkLeave);
    });

    // Cleanup event listeners when the component unmounts
    return () => {
      links.forEach((link) => {
        link.removeEventListener('mouseenter', handleLinkEnter);
        link.removeEventListener('mouseleave', handleLinkLeave);
      });
    };
  }, []);

  function closeMob() {
    let menus = document.querySelector(".mob-menu-items")
    setTimeout(() => {
      menus.classList.remove("open-mob-menu")
    }, 400);
  }
  window.addEventListener("load", closeMob);

  document.addEventListener('mousemove', function(e) {
    let cursor1 = document.querySelector('.cursor1')
    let cursor2 = document.querySelector('.cursor2')
    cursor1.style.cssText = cursor2.style.cssText = "left: " + e.clientX + "px; top: " + e.clientY + "px;"
  })

  return (
    <div className='Frame'>
      <div style={loading === true ? {display: "block"} : {display: "none"}} className="loader">
        <div className="cen-load">
          <svg class="pencil" viewBox="0 0 200 200" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="pencil-eraser">
          <rect rx="5" ry="5" width="30" height="30"></rect>
        </clipPath>
      </defs>
      <circle class="pencil__stroke" r="70" fill="none" stroke="currentColor" stroke-width="2" stroke-dasharray="439.82 439.82" stroke-dashoffset="439.82" stroke-linecap="round" transform="rotate(-113,100,100)" />
      <g class="pencil__rotate" transform="translate(100,100)">
        <g fill="none">
          <circle class="pencil__body1" r="64" stroke="hsl(223,90%,50%)" stroke-width="30" stroke-dasharray="402.12 402.12" stroke-dashoffset="402" transform="rotate(-90)" />
          <circle class="pencil__body2" r="74" stroke="hsl(223,90%,60%)" stroke-width="10" stroke-dasharray="464.96 464.96" stroke-dashoffset="465" transform="rotate(-90)" />
          <circle class="pencil__body3" r="54" stroke="hsl(223,90%,40%)" stroke-width="10" stroke-dasharray="339.29 339.29" stroke-dashoffset="339" transform="rotate(-90)" />
        </g>
        <g class="pencil__eraser" transform="rotate(-90) translate(49,0)">
          <g class="pencil__eraser-skew">
            <rect fill="hsl(223,90%,70%)" rx="5" ry="5" width="30" height="30" />
            <rect fill="hsl(223,90%,60%)" width="5" height="30" clip-path="url(#pencil-eraser)" />
            <rect fill="hsl(223,10%,90%)" width="30" height="20" />
            <rect fill="hsl(223,10%,70%)" width="15" height="20" />
            <rect fill="hsl(223,10%,80%)" width="5" height="20" />
            <rect fill="hsla(223,10%,10%,0.2)" y="6" width="30" height="2" />
            <rect fill="hsla(223,10%,10%,0.2)" y="13" width="30" height="2" />
          </g>
        </g>
        <g class="pencil__point" transform="rotate(-90) translate(49,-30)">
          <polygon fill="hsl(33,90%,70%)" points="15 0,30 30,0 30" />
          <polygon fill="hsl(33,90%,50%)" points="15 0,6 30,0 30" />
          <polygon fill="hsl(223,10%,10%)" points="15 0,20 10,10 10" />
        </g>
      </g>
          </svg>
        </div>
      </div>
        <FrNavbar />
        <div style={{display: "none"}} className="Main"></div>
        <div style={{display: "none"}} className="logo-dark"></div>
        <div style={{display: "none"}} className="logo-light"></div>
        <div className={`cursor1 ${cursorStyle1}`}></div>
        <div className={`cursor2 ${cursorStyle2}`}></div>
        <div className="shapes">
            <div className="shape1"></div>
            <div className="shape2"></div>
        </div>
        <div className="frame-wrapper">
            <div className="frame-main">
                <div className="frs">
                    <div className="col">
                        <div className="fr-left">
                            <h1 className='text-gradient frame-title'>Foundation kursi</h1>
                            <p>Ushbu kursimiz yordamida Frontend yo'nalishiga ilk qadam tashlang va web qanday ishlashi haqida ko'nikmaga ega bo'ling.</p>
                            <div className="course-info">
                                <h2>Kurs haqida</h2>
                                <ul>
                                    <li>
                                        <span><ion-icon name="person-outline"></ion-icon></span>
                                        <p>Ustoz: Raxmatova Sitora</p>
                                    </li>
                                    <li>
                                        <span><ion-icon name="time-outline"></ion-icon></span>
                                        <p>Kurs davomiyligi: 2 oy</p>
                                    </li>
                                    <li>
                                        <span><ion-icon name="wallet-outline"></ion-icon></span>
                                        <p>Kurs narxi: Bepul</p>
                                    </li>
                                </ul>
                                <div className="frames">
                                    <h2>Siz quyidagilarni o'rganasiz</h2>
                                    <ul>
                                        <li><img src={html} /></li>
                                        <li><img src={css} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="fr-right">
                            <img src={foundationset} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="btns">
                            <a href="#contact" className="btn-i-primary-dark-hovered">Kursga yozilish</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Elements />
        <Courses />
        <Contact />
        <Footer />
    </div>
  )
}

export default Foundation