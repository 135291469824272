import React, {useState, useEffect} from 'react'
import logo1 from "../Contents/images/logoblack.png";
import logo2 from "../Contents/images/logowhite2.png";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

const FrNavbar = () => {   
  const [day, setDay] = useState(true)

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour >= 6 && hour <= 18) {
      setDay(true);
      let body = document.querySelector("body")
    let nav = document.querySelectorAll(".Navbar")
    let main = document.querySelector(".Main")
    let p = document.querySelectorAll("p")
    let a = document.querySelectorAll("a")
    let mobmenu = document.querySelector('.mob-menu')
    let mobmenuitems = document.querySelector('.mob-menu-items')
    let ra = document.querySelectorAll('.r-a')
    let rsa = document.querySelectorAll('.r-s-a')
    let l1 = document.querySelector('.logo-dark')
    let l2 = document.querySelector('.logo-light')
    let lc1 = document.querySelector('.c-logo-dark')
    let lc2 = document.querySelector('.c-logo-light')
    let shape1 = document.querySelectorAll('.shape1')
    let shape2 = document.querySelectorAll('.shape2')
    let cardmain = document.querySelector('.card-title')
    let card = document.querySelectorAll('.card')
    let contact = document.querySelectorAll('.con-main')
    let h1 = document.querySelectorAll('h1')
    let h2 = document.querySelectorAll('h2')
    let label = document.querySelectorAll('label')
    let footext = document.querySelector('.foo-b-text')
    let foo = document.querySelector('.Foo')

    body.style.backgroundColor = "transparent"
      for (let nv = 0; nv < nav.length; nv++) {
        nav[nv].style.backgroundColor = "#F5F8FAFF"
      }
      foo.style.backgroundColor = "#F5F8FAFF"
      for (let con = 0; con < contact.length; con++) {
        contact[con].style.backgroundColor = "#E9F8FEFF"
        contact[con].style.color = "#65676b"
      }
      mobmenu.style.color = "#65676b"
      mobmenuitems.style.backgroundColor = "transparent"
      mobmenuitems.style.color = "#65676b"
      mobmenuitems.classList.add('mob-come')
      main.style.backgroundColor = "transparent"
      body.style.color = "black"
      for (let i = 0; i < ra.length; i++) {
        ra[i].style.color = "#b00f0f"
      }
      for (let j = 0; j < ra.length; j++) {
        rsa[j].style.color = "#b00f0f"
        rsa[j].border = "1px solid #65676b"
      }
      l1.style.display = "block"
      l2.style.display = "none"
      lc1.style.display = "block"
      lc2.style.display = "none"
      for (let shp = 0; shp < shape1.length; shp++) {
        shape1[shp].style.backgroundColor = "rgba(255, 0, 255, 0.308)"
        shape2[shp].style.backgroundColor = "rgba(0, 255, 255, 0.349)"
      }
      for (let k = 0; k < card.length; k++) {
        card[k].style.backgroundColor = "#E9F8FEFF"
        card[k].style.color = "#65676b"
      }
      for (let w = 0; w < h1.length; w++) {
        h1[w].style.color = "#65676b"
      }
      for (let q = 0; q < h2.length; q++) {
        h2[q].style.color = "#65676b"
      }
      for (let v = 0; v < p.length; v++) {
        p[v].style.color = "#65676b"
      }
      for (let f = 0; f < a.length; f++) {
        a[f].style.color = "#65676b"
      }
      for (let b = 0; b < label.length; b++) {
        label[b].style.color = "#65676b"
      }
      footext.style.color = "#000"
    } else {
      setDay(false);
      let body = document.querySelector("body")
    let nav = document.querySelectorAll(".Navbar")
    let main = document.querySelector(".Main")
    let p = document.querySelectorAll("p")
    let a = document.querySelectorAll("a")
    let mobmenu = document.querySelector('.mob-menu')
    let mobmenuitems = document.querySelector('.mob-menu-items')
    let ra = document.querySelectorAll('.r-a')
    let rsa = document.querySelectorAll('.r-s-a')
    let l1 = document.querySelector('.logo-dark')
    let l2 = document.querySelector('.logo-light')
    let lc1 = document.querySelector('.c-logo-dark')
    let lc2 = document.querySelector('.c-logo-light')
    let shape1 = document.querySelectorAll('.shape1')
    let shape2 = document.querySelectorAll('.shape2')
    let card = document.querySelectorAll('.card')
    let cardmain = document.querySelector('.card-title')
    let contact = document.querySelectorAll('.con-main')
    let h1 = document.querySelectorAll('h1')
    let h2 = document.querySelectorAll('h2')
    let label = document.querySelectorAll('label')
    let footext = document.querySelector('.foo-b-text')
    let foo = document.querySelector('.Foo')

    body.style.backgroundColor = "#140342"
      for (let nv = 0; nv < nav.length; nv++) {
        nav[nv].style.backgroundColor = "#060606"
      }
      for (let con = 0; con < contact.length; con++) {
        contact[con].style.backgroundColor = "#1E1E1EFF"  
        contact[con].style.color = "white"
      }
      mobmenu.style.color = "white"
      foo.style.backgroundColor = "#0606069F"
      mobmenuitems.style.color = "white"
      mobmenuitems.style.backgroundColor = "#0606069F"
      mobmenuitems.classList.remove('mob-come')
      main.style.backgroundColor = "#0606069F"
      body.style.color = "white"
      for (let i = 0; i < ra.length; i++) {
        ra[i].style.color = "white"
      }
      for (let j = 0; j < rsa.length; j++) {
        rsa[j].style.color = "white"
        rsa[j].border = "1px solid #fff"
      }
      l1.style.display = "none"
      l2.style.display = "block"
      lc1.style.display = "none"
      lc2.style.display = "block"
      for (let shp = 0; shp < shape1.length; shp++) {
        shape1[shp].style.backgroundColor = "rgba(255, 0, 255, 0.448)"
        shape2[shp].style.backgroundColor = "rgba(0, 255, 255, 0.449)"
      }
      for (let k = 0; k < card.length; k++) {
        card[k].style.backgroundColor = "#1E1E1EFF"
        card[k].style.color = "white"
      }
      cardmain.style.backgroundColor = "transparent";
      for (let w = 0; w < h1.length; w++) {
        h1[w].style.color = "#fff"
      }
      for (let q = 0; q < h2.length; q++) {
        h2[q].style.color = "#fff"
      }
      for (let v = 0; v < p.length; v++) {
        p[v].style.color = "#fff"
      }
      for (let f = 0; f < a.length; f++) {
        a[f].style.color = "#fff"
      }
      for (let b = 0; b < label.length; b++) {
        label[b].style.color = "#fff"
      }
      footext.style.color = "white"
    }
  }, []);
  useEffect(() => {
    const hour = new Date().getHours();
    const intervalId = setInterval(() => {
      if (hour >= 6 && hour <= 18) {
        setDay(true);
        let body = document.querySelector("body")
      let nav = document.querySelectorAll(".Navbar")
      let main = document.querySelector(".Main")
      let p = document.querySelectorAll("p")
      let a = document.querySelectorAll("a")
      let mobmenu = document.querySelector('.mob-menu')
      let mobmenuitems = document.querySelector('.mob-menu-items')
      let ra = document.querySelectorAll('.r-a')
      let rsa = document.querySelectorAll('.r-s-a')
      let l1 = document.querySelector('.logo-dark')
      let l2 = document.querySelector('.logo-light')
      let lc1 = document.querySelector('.c-logo-dark')
      let lc2 = document.querySelector('.c-logo-light')
      let shape1 = document.querySelectorAll('.shape1')
      let shape2 = document.querySelectorAll('.shape2')
      let cardmain = document.querySelector('.card-title')
      let card = document.querySelectorAll('.card')
      let contact = document.querySelectorAll('.con-main')
      let h1 = document.querySelectorAll('h1')
      let h2 = document.querySelectorAll('h2')
      let label = document.querySelectorAll('label')
      let footext = document.querySelector('.foo-b-text')
      let foo = document.querySelector('.Foo')

      body.style.backgroundColor = "transparent"
        for (let nv = 0; nv < nav.length; nv++) {
          nav[nv].style.backgroundColor = "#F5F8FAFF"
        }
        foo.style.backgroundColor = "#F5F8FAFF"
        for (let con = 0; con < contact.length; con++) {
          contact[con].style.backgroundColor = "#E9F8FEFF"
          contact[con].style.color = "#65676b"
        }
        mobmenu.style.color = "#65676b"
        mobmenuitems.style.backgroundColor = "transparent"
        mobmenuitems.style.color = "#65676b"
        mobmenuitems.classList.add('mob-come')
        main.style.backgroundColor = "transparent"
        body.style.color = "black"
        for (let i = 0; i < ra.length; i++) {
          ra[i].style.color = "#b00f0f"
        }
        for (let j = 0; j < ra.length; j++) {
          rsa[j].style.color = "#b00f0f"
          rsa[j].border = "1px solid #65676b"
        }
        l1.style.display = "block"
        l2.style.display = "none"
        lc1.style.display = "block"
        lc2.style.display = "none"
        for (let shp = 0; shp < shape1.length; shp++) {
          shape1[shp].style.backgroundColor = "rgba(255, 0, 255, 0.308)"
          shape2[shp].style.backgroundColor = "rgba(0, 255, 255, 0.349)"
        }
        for (let k = 0; k < card.length; k++) {
          card[k].style.backgroundColor = "#E9F8FEFF"
          card[k].style.color = "#65676b"
        }
        for (let w = 0; w < h1.length; w++) {
          h1[w].style.color = "#65676b"
        }
        for (let q = 0; q < h2.length; q++) {
          h2[q].style.color = "#65676b"
        }
        for (let v = 0; v < p.length; v++) {
          p[v].style.color = "#65676b"
        }
        for (let f = 0; f < a.length; f++) {
          a[f].style.color = "#65676b"
        }
        for (let b = 0; b < label.length; b++) {
          label[b].style.color = "#65676b"
        }
        footext.style.color = "#000"
      } else {
        setDay(false);
        let body = document.querySelector("body")
      let nav = document.querySelectorAll(".Navbar")
      let main = document.querySelector(".Main")
      let p = document.querySelectorAll("p")
      let a = document.querySelectorAll("a")
      let mobmenu = document.querySelector('.mob-menu')
      let mobmenuitems = document.querySelector('.mob-menu-items')
      let ra = document.querySelectorAll('.r-a')
      let rsa = document.querySelectorAll('.r-s-a')
      let l1 = document.querySelector('.logo-dark')
      let l2 = document.querySelector('.logo-light')
      let lc1 = document.querySelector('.c-logo-dark')
      let lc2 = document.querySelector('.c-logo-light')
      let shape1 = document.querySelectorAll('.shape1')
      let shape2 = document.querySelectorAll('.shape2')
      let card = document.querySelectorAll('.card')
      let cardmain = document.querySelector('.card-title')
      let contact = document.querySelectorAll('.con-main')
      let h1 = document.querySelectorAll('h1')
      let h2 = document.querySelectorAll('h2')
      let label = document.querySelectorAll('label')
      let footext = document.querySelector('.foo-b-text')
      let foo = document.querySelector('.Foo')

      body.style.backgroundColor = "#140342"
        for (let nv = 0; nv < nav.length; nv++) {
          nav[nv].style.backgroundColor = "#060606"
        }
        for (let con = 0; con < contact.length; con++) {
          contact[con].style.backgroundColor = "#1E1E1EFF"  
          contact[con].style.color = "white"
        }
        mobmenu.style.color = "white"
        foo.style.backgroundColor = "#0606069F"
        mobmenuitems.style.color = "white"
        mobmenuitems.style.backgroundColor = "#0606069F"
        mobmenuitems.classList.remove('mob-come')
        main.style.backgroundColor = "#0606069F"
        body.style.color = "white"
        for (let i = 0; i < ra.length; i++) {
          ra[i].style.color = "white"
        }
        for (let j = 0; j < rsa.length; j++) {
          rsa[j].style.color = "white"
          rsa[j].border = "1px solid #fff"
        }
        l1.style.display = "none"
        l2.style.display = "block"
        lc1.style.display = "none"
        lc2.style.display = "block"
        for (let shp = 0; shp < shape1.length; shp++) {
          shape1[shp].style.backgroundColor = "rgba(255, 0, 255, 0.448)"
          shape2[shp].style.backgroundColor = "rgba(0, 255, 255, 0.449)"
        }
        for (let k = 0; k < card.length; k++) {
          card[k].style.backgroundColor = "#1E1E1EFF"
          card[k].style.color = "white"
        }
        cardmain.style.backgroundColor = "transparent";
        for (let w = 0; w < h1.length; w++) {
          h1[w].style.color = "#fff"
        }
        for (let q = 0; q < h2.length; q++) {
          h2[q].style.color = "#fff"
        }
        for (let v = 0; v < p.length; v++) {
          p[v].style.color = "#fff"
        }
        for (let f = 0; f < a.length; f++) {
          a[f].style.color = "#fff"
        }
        for (let b = 0; b < label.length; b++) {
          label[b].style.color = "#fff"
        }
        footext.style.color = "white"
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  function whatDay() {
    setTimeout(() => {
      setDay(!day)
      let body = document.querySelector("body")
      let nav = document.querySelectorAll(".Navbar")
      let main = document.querySelector(".Main")
      let p = document.querySelectorAll("p")
      let a = document.querySelectorAll("a")
      let mobmenu = document.querySelector('.mob-menu')
      let mobmenuitems = document.querySelector('.mob-menu-items')
      let ra = document.querySelectorAll('.r-a')
      let rsa = document.querySelectorAll('.r-s-a')
      let l1 = document.querySelector('.logo-dark')
      let l2 = document.querySelector('.logo-light')
      let lc1 = document.querySelector('.c-logo-dark')
      let lc2 = document.querySelector('.c-logo-light')
      let shape1 = document.querySelectorAll('.shape1')
      let shape2 = document.querySelectorAll('.shape2')
      let cardmain = document.querySelector('.card-title')
      let card = document.querySelectorAll('.card')
      let contact = document.querySelectorAll('.con-main')
      let h1 = document.querySelectorAll('h1')
      let h2 = document.querySelectorAll('h2')
      let label = document.querySelectorAll('label')
      let footext = document.querySelector('.foo-b-text')
      let foo = document.querySelector('.Foo')
      if (day === false) {
        body.style.backgroundColor = "transparent"
        for (let nv = 0; nv < nav.length; nv++) {
          nav[nv].style.backgroundColor = "#F5F8FAFF"
        }
        foo.style.backgroundColor = "#F5F8FAFF"
        for (let con = 0; con < contact.length; con++) {
          contact[con].style.backgroundColor = "#E9F8FEFF"
          contact[con].style.color = "#65676b"
        }
        mobmenu.style.color = "#65676b"
        main.style.backgroundColor = "transparent"
        mobmenuitems.style.color = "#65676b"
        mobmenuitems.style.backgroundColor = "transparent"
        mobmenuitems.classList.add('mob-come')
        body.style.color = "black"
        for (let i = 0; i < ra.length; i++) {
          ra[i].style.color = "#b00f0f"
        }
        for (let j = 0; j < ra.length; j++) {
          rsa[j].style.color = "#b00f0f"
          rsa[j].border = "1px solid #65676b"
        }
        l1.style.display = "block"
        l2.style.display = "none"
        lc1.style.display = "block"
        lc2.style.display = "none"
        for (let shp = 0; shp < shape1.length; shp++) {
          shape1[shp].style.backgroundColor = "rgba(255, 0, 255, 0.308)"
          shape2[shp].style.backgroundColor = "rgba(0, 255, 255, 0.349)"
        }
        for (let k = 0; k < card.length; k++) {
          card[k].style.backgroundColor = "#E9F8FEFF"
          card[k].style.color = "#65676b"
        }
        cardmain.style.backgroundColor = "transparent";
        for (let w = 0; w < h1.length; w++) {
          h1[w].style.color = "#65676b"
        }
        for (let q = 0; q < h2.length; q++) {
          h2[q].style.color = "#65676b"
        }
        for (let v = 0; v < p.length; v++) {
          p[v].style.color = "#65676b"
        }
        for (let f = 0; f < a.length; f++) {
          a[f].style.color = "#65676b"
        }
        for (let b = 0; b < label.length; b++) {
          label[b].style.color = "#65676b"
        }
        footext.style.color = "#000"
      } else {
        body.style.backgroundColor = "#140342"
        for (let nv = 0; nv < nav.length; nv++) {
          nav[nv].style.backgroundColor = "#060606"
        }
        for (let con = 0; con < contact.length; con++) {
          contact[con].style.backgroundColor = "#1E1E1EFF"  
          contact[con].style.color = "white"
        }
        mobmenu.style.color = "white"
        foo.style.backgroundColor = "#0606069F"
        mobmenuitems.style.color = "white"
        mobmenuitems.style.backgroundColor = "#0606069F"
        mobmenuitems.classList.remove('mob-come')
        main.style.backgroundColor = "#0606069F"
        body.style.color = "white"
        for (let i = 0; i < ra.length; i++) {
          ra[i].style.color = "white"
        }
        for (let j = 0; j < rsa.length; j++) {
          rsa[j].style.color = "white"
          rsa[j].border = "1px solid #fff"
        }
        l1.style.display = "none"
        l2.style.display = "block"
        lc1.style.display = "none"
        lc2.style.display = "block"
        for (let shp = 0; shp < shape1.length; shp++) {
          shape1[shp].style.backgroundColor = "rgba(255, 0, 255, 0.448)"
          shape2[shp].style.backgroundColor = "rgba(0, 255, 255, 0.449)"
        }
        for (let k = 0; k < card.length; k++) {
          card[k].style.backgroundColor = "#1E1E1EFF"
          card[k].style.color = "white"
        }
        cardmain.style.backgroundColor = "transparent";
        for (let w = 0; w < h1.length; w++) {
          h1[w].style.color = "#fff"
        }
        for (let q = 0; q < h2.length; q++) {
          h2[q].style.color = "#fff"
        }
        for (let v = 0; v < p.length; v++) {
          p[v].style.color = "#fff"
        }
        for (let f = 0; f < a.length; f++) {
          a[f].style.color = "#fff"
        }
        for (let b = 0; b < label.length; b++) {
          label[b].style.color = "#fff"
        }
        footext.style.color = "white"
      }
    }, 150);
  }
  
    
    function openMobMenu() {
      let menus = document.querySelector(".mob-menu-items")
      menus.classList.toggle("open-mob-menu")
    }
  
  
    function autoCloseMobMenu() {
      let menus = document.querySelector(".mob-menu-items")
      let menuline = document.querySelector(".menu-un-line")
      setTimeout(() => {
        menuline.style.display = "none"
        menus.classList.remove("open-mob-menu")
      }, 400);
    }


    document.addEventListener('DOMContentLoaded', function() {
      // Get all anchor links
      const anchorLinks = document.querySelectorAll('a[href^="#"]');
    
      // Add click event listeners to each anchor link
      anchorLinks.forEach(function(anchorLink) {
        anchorLink.addEventListener('click', function(e) {
          // Prevent the default jump-to-anchor behavior
          e.preventDefault();
    
          // Get the target element's id from the href attribute
          const targetId = anchorLink.getAttribute('href').substring(1);
    
          // Find the target element by its id
          const targetElement = document.getElementById(targetId);
    
          // Scroll to the target element smoothly
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        });
      });
    });
    
  

  return (
    <div className="Navbar" id="navbar">
      <div className="brand">
        <NavLink to="/">
            <img style={ day === true ? { display: "block" } : { display: "none" } } src={logo1} />
            <img style={ day === true ? { display: "none" } : { display: "block" } } src={logo2} />
        </NavLink>
      </div>
      <div className="items">
        <div className="day-night">
          <div onClick={whatDay} style={day === true ? {display: "block"}:{display: "none"}} className="day">
            <ion-icon name="sunny-outline"></ion-icon>
          </div>
          <div onClick={whatDay} style={day === false ? {display: "block"}:{display: "none"}} className="night">
            <ion-icon name="moon-outline"></ion-icon>
          </div>
        </div>
        <ul className="mob-menu-items" >
          <li onClick={autoCloseMobMenu}>
            <a style={ day === true ? { color: "#65676b" }: { color: "white" } } href='#elements' >Biz haqimizda</a>
            <span className="menu-un-line"></span>
          </li>
          <li onClick={autoCloseMobMenu}>
            <a style={ day === true ? { color: "#65676b" }: { color: "white" } } href="#courses" >Kurslar</a>
            <span className="menu-un-line"></span>
          </li>
          <li onClick={autoCloseMobMenu}>
            <a style={ day === true ? { color: "#65676b" }: { color: "white" } } href="#contact" >Aloqa</a>
            <span className="menu-un-line"></span>
          </li>
        </ul>
        <button className="mob-menu" onClick={openMobMenu}><ion-icon name="menu-outline"></ion-icon></button>
      </div>
    </div>
  )
}

export default FrNavbar