import React from 'react'
import residentimg from '../Contents/images/certificateresident.png'
import fguvohnoma from '../Contents/images/firmaguvohnomasi.jpg'



import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

class Footer extends React.Component {
  componentDidMount() {
    Events.scrollEvent.register('begin', function (to, element) {
      console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function (to, element) {
      console.log('end', arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  scrollToBottom() {
    scroll.scrollToBottom();
  }

  
  render() {
      const year = new Date().getFullYear()
    return (
        <div className='Foo' id='footer'>
            <div className="foo-upper">
            <div className="foo-main">
                <div className="box">
                    <h1>Tezkor havolalar</h1>
                    <div className="lines">
                        <span></span>
                        <span></span>
                    </div>
                    <div className="items">
                        <ul>
                            <li><Link to="elements" smooth={true} duration={500}>Biz haqimizda</Link></li>
                            <li><Link to="contact" smooth={true} duration={500}>Biz bilan aloqa</Link></li>
                            {/* <li><a href="#">Bizning kompaniya</a></li> */}
                            {/* <li><a href="#">Bizning xizmatlar</a></li> */}
                            <li><Link to="courses" smooth={true} duration={500}>Kurslarimiz</Link></li>
                            {/* <li><a href="#">Portfolio</a></li> */}
                        </ul>
                    </div>
                </div>
                <div className="box">
                    <div className="box-right">
                        <h1>Manzilimiz</h1>
                        <div className="lines">
                            <span></span>
                            <span></span>
                        </div>
                        <div className="items">
                            <p>Navoiy viloyati, Karmana tumani, Arg'un MFY mahalla binosining faollar zali</p>
                            <a href='#contact' className="btn-i-primary">Biz bilan bog'laning</a>
                        </div>
                    </div>
                    <div className="box-right">
                        <div className="img-box">
                            <a href={residentimg} download style={{textDecoration: "none"}}>
                                <img src={residentimg}/>
                            </a>
                            <p>IT Park rezidenti</p>
                        </div>
                        <div className="img-box">
                            <a href={fguvohnoma} download style={{textDecoration: "none"}}>
                                <img src={fguvohnoma} />
                            </a>
                            <p>Firma guvohnomasi</p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="foo-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d190.66200550650112!2d65.3529466!3d40.1290931!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f51c7423ba8c0bd%3A0xc4c9c71638e38e04!2s%22SIFAT%22%20O&#39;quv%20Markazi!5e0!3m2!1suz!2s!4v1694235374639!5m2!1suz!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="foo-bottom">
                <p className='foo-b-text'>Sifat Dev. {year} &copy;</p>
            </div>
            </div>
            <div class="foo-lines">
                <div class="foo-line"></div>
                <div class="foo-line"></div>
                <div class="foo-line"></div>
            </div>
        </div>
    );
  }
}

export default Footer;