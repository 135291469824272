import React from 'react' 
import logo from '../Contents/images/logowhite2.png'
import { Tilt } from 'react-tilt';
import { Link } from 'react-router-dom';
import '../Error.css';


const ErrorPage = () => {

    const defaultOptions = {
        reverse:        false,  // reverse the tilt direction
        perspective: 100,
        max:            5,     // max tilt rotation (degrees)
        scale:          1.001,    // 2 = 200%, 1.5 = 150%, etc..
        speed:          100,   // Speed of the enter/exit transition
        transition:     true,   // Set a transition on enter/exit.
        axis:           null,   // What axis should be disabled. Can be X or Y.
        reset:          true,    // If the tilt effect has to be reset on exit.
        easing:         "cubic-bezier(.003,.098,.052,.099)",    // Easing on enter/exit.
    }

    function menuOpen() {
        document.querySelector('.menu_links').classList.toggle('menu-opener')
    }

  return (
      <div className='Error'>
        <div className="about">
   <a className="bg_links social portfolio" href="https://t.me/sifatedu_admin" target="_blank">
      <span className="icon"><i class="fa-brands fa-telegram"></i></span>
   </a>
   <a className="bg_links social dribbble" href="tel:+998883780808" target="_blank">
      <span className="icon"><i class="fa-solid fa-phone"></i></span>
   </a>
   <a className="bg_links social linkedin" href="https://instagram.com/sifatedu?igshid=MzRlODBiNWFlZA==" target="_blank">
      <span className="icon"><i class="fa-brands fa-instagram"></i></span>
   </a>
   <a className="bg_links logo"><ion-icon style={{rotate: "-45deg", color: "#fff", fontSize: "24px"}} name="link-outline"></ion-icon></a>
</div>

    <nav>
        <div className="menu">
            <Link className='logo-nav-err' to="/"><img style={{width: "150px"}} src={logo} /></Link>
            <div className="menu_links">
                <a href="../#elements" className="link">Biz haqimizda</a>
                <a href="../#elements" className="link">Kurslar</a>
                <a href="../#elements" className="link">Aloqa</a>
            </div>
            <div onClick={menuOpen} className="menu_icon">
                <span className="icon"></span>
            </div>
        </div>
    </nav>

            <Tilt options={defaultOptions}>
    <section className="wrapper">

        <div className="container">

            <div id="scene" className="scene" data-hover-only="false">


                <div className="circle" data-depth="1.2"></div>

                <div className="one" data-depth="0.9">
                    <div className="content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>

                <div className="two" data-depth="0.60">
                    <div className="content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>

                <div className="three" data-depth="0.40">
                    <div className="content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>

                <p className="p404" data-depth="0.50">404</p>
                <p className="p404" data-depth="0.10">404</p>

            </div>

            <div className="text">
                <article>
                    <p>Uh oh! Sahifani topa olmadik! Iltimos URLni to'g'ri kiritilganiga e'tibor bering yoki bosh sahifaga qayting</p>
                    <button><a href="/">Bosh sahifa</a></button>
                </article>
            </div>

        </div>
    </section>
    </Tilt>
    </div>
  )
}

export default ErrorPage