import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./Components/Main";
// import Contact from "./Components/Contact";
import Front from "./Components/Front";
import Back from "./Components/Back";
import Foundation from "./Components/Foundation";
import ComputerAssembly from "./Components/ComputerAssembly";
import ComputerLiteracy from "./Components/ComputerLiteracy";
// import Seo from "./Components/Seo";
import Cyber from "./Components/Cyber";
import Java from "./Components/Java";
import PythonCourse from "./Components/PythonCourse";
import KotlinCourse from "./Components/KotlinCourse";
import ErrorPage from "./Components/ErrorPage";

// require('dotenv').config();


function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        {/* <Route path="/contact" element={ <Contact /> } /> */}
        <Route path="/frontend" element={ <Front /> } />
        <Route path="/backend" element={ <Back /> } />
        <Route path="/foundation" element={ <Foundation /> } />
        <Route path="/pythoncourse" element={ <PythonCourse /> } />
        <Route path="/kotlincourse" element={ <KotlinCourse /> } />
        {/* <Route path="/kompyuteryigish" element={ <ComputerAssembly /> } /> */}
        {/* <Route path="/kompyutersavodxonligi" element={ <ComputerLiteracy /> } /> */}
        {/* <Route path="/seo" element={ <Seo /> } /> */}
        <Route path="/javacourse" element={ <Java /> } />

        <Route path="/cybercourse" element={ <Cyber /> } />
        <Route path="*" element={ <ErrorPage /> } />
      </Routes>
    </div>
  );
}

export default App;
