import React, { useState, useEffect } from 'react'
import FrNavbar from './FrNavbar'
import Footer from './Footer'
import Contact from './Contact'
import Elements from './Elements'
import html from '../Contents/images/html.png'
import css from '../Contents/images/css.png'
import python from '../Contents/images/python.png'
import django from '../Contents/images/django.png'
import cyber from '../Contents/images/cyberset.png'
import Courses from './Courses'

const Cyber = () => {
    const [cursorStyle1, setCursorStyle1] = useState('cursor-default1');
  const [cursorStyle2, setCursorStyle2] = useState('cursor-default2');

  // Event handlers to change cursor styles
  const handleLinkEnter = () => {
    setCursorStyle1('cursor-hover1');
    setCursorStyle2('cursor-hover2');
  };

  const handleLinkLeave = () => {
    setCursorStyle1('cursor-default1');
    setCursorStyle2('cursor-default2');
  };

  useEffect(() => {
    const links = document.querySelectorAll('a');

    links.forEach((link) => {
      link.addEventListener('mouseenter', handleLinkEnter);
      link.addEventListener('mouseleave', handleLinkLeave);
    });

    // Cleanup event listeners when the component unmounts
    return () => {
      links.forEach((link) => {
        link.removeEventListener('mouseenter', handleLinkEnter);
        link.removeEventListener('mouseleave', handleLinkLeave);
      });
    };
  }, []);

  function closeMob() {
    let menus = document.querySelector(".mob-menu-items")
    setTimeout(() => {
      menus.classList.remove("open-mob-menu")
    }, 400);
  }
  window.addEventListener("load", closeMob);

  document.addEventListener('mousemove', function(e) {
    let cursor1 = document.querySelector('.cursor1')
    let cursor2 = document.querySelector('.cursor2')
    cursor1.style.cssText = cursor2.style.cssText = "left: " + e.clientX + "px; top: " + e.clientY + "px;"
  })

  return (
    <div className='Frame'>
        <FrNavbar />
        <div style={{display: "none"}} className="info-right"></div>
        <div style={{display: "none"}} className="btn-close"></div>
        <div style={{display: "none"}} className="Main"></div>
        <div style={{display: "none"}} className="right-swipe-side"></div>
        <div style={{display: "none"}} className="logo-dark"></div>
        <div style={{display: "none"}} className="logo-light"></div>
        <div style={{display: "none"}} className="r-logo-dark"></div>
        <div style={{display: "none"}} className="r-logo-light"></div>
        <div className={`cursor1 ${cursorStyle1}`}></div>
        <div className={`cursor2 ${cursorStyle2}`}></div>
        <div className="shapes">
            <div className="shape1"></div>
            <div className="shape2"></div>
        </div>
        <div className="frame-wrapper">
            <div className="frame-main">
                <div className="frs">
                    <div className="col">
                        <div className="fr-left">
                            <h1 className='text-gradient frame-title'>Kiberxavfsizlik kursi</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo natus hic sunt obcaecati similique! Rerum explicabo aspernatur impedit ex eligendi.</p>
                            <div className="course-info">
                                <h2>Kurs haqida</h2>
                                <ul>
                                    <li>
                                        <span><ion-icon name="person-outline"></ion-icon></span>
                                        <p>Ustoz: Istam Rustamov</p>
                                    </li>
                                    <li>
                                        <span><ion-icon name="time-outline"></ion-icon></span>
                                        <p>Kurs davomiyligi: 2 oy</p>
                                    </li>
                                    <li>
                                        <span><ion-icon name="wallet-outline"></ion-icon></span>
                                        <p>Kurs narxi: 1 000 000 so'm</p>
                                    </li>
                                </ul>
                                <div className="frames">
                                    <h2>Siz quyidagilarni o'rganasiz</h2>
                                    <ul>
                                        <li><img src={html} /></li>
                                        <li><img src={css} /></li>
                                        <li><img src={python} /></li>
                                        <li><img src={django} /></li>
                                    </ul>
                                    <ul className='word-skills'>
                                      <li>
                                        <span><ion-icon name="checkmark-outline"></ion-icon></span>
                                        <p>Lorem ipsum</p>
                                      </li>
                                      <li>
                                        <span><ion-icon name="checkmark-outline"></ion-icon></span>
                                        <p>Lorem ipsum</p>
                                      </li>
                                      <li>
                                        <span><ion-icon name="checkmark-outline"></ion-icon></span>
                                        <p>Lorem ipsum</p>
                                      </li>
                                      <li>
                                        <span><ion-icon name="checkmark-outline"></ion-icon></span>
                                        <p>Lorem ipsum</p>
                                      </li>
                                      <li>
                                        <span><ion-icon name="checkmark-outline"></ion-icon></span>
                                        <p>Lorem ipsum</p>
                                      </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="fr-right">
                            <img src={cyber} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="btns">
                            <a href="#" className="btn-i-primary-dark-hovered">Kursga yozilish</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Elements />
        <Courses />
        <Contact />
        <Footer />
    </div>
  )
}

export default Cyber