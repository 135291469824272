import React, { useState, useEffect } from "react";
import logodark from "../Contents/images/logoblack.png";
import logolight from "../Contents/images/logowhite2.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { isMobile, isBrowser, deviceDetect } from 'react-device-detect';

const Contact = () => {
  const botToken = process.env.REACT_APP_BOT_TOKEN;
  const chatId = process.env.REACT_APP_CHAT_ID

  const sendTelegramMessage = async (name, number, account, message) => {
    try {
      const response = await axios.post(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          chat_id: chatId,
          text: `-----------------------\n Name: ${name}\n Phone: ${number}\n-----------------------\n Shahar: ${myPosCity}\n Map: ${myAdress}\n-----------------------\n Device Information: \n • Device: ${deviceInfo.deviceType} | ${deviceInfo.userAgent}\n • Browser: ${deviceDetect().browserName}\n • Operating System: ${deviceDetect().osName}\n • Mobile: ${isMobile}\n-----------------------\n Message: ${usermessage}`,
        }
      );

      if (response.status === 200) {
        document.querySelector('.popup-success').style.display = "block"
      } else {
        document.querySelector('.popup-nosuccess').style.display = "block"
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [deviceInfo, setDeviceInfo] = useState(null);

  useEffect(() => {
    const detectDevice = () => {
      const userAgent = navigator.userAgent;
      const isMobileDevice = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
      const deviceType = isMobileDevice ? "Mobile" : "Desktop";

      setDeviceInfo({
        userAgent,
        deviceType,
      });
    };
    detectDevice();
  }, []);
  console.log(deviceInfo)

  const [username, setUsername] = useState("");
  const [usernumber, setUsernumber] = useState("");
  // const [useraccount, setUseraccount] = useState("");
  const [usermessage, setUsermessage] = useState("");
  const [myPosCity, setCity] = useState('')
  const [myAdress, setMyAdress] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const name = username;
    const number = `+998${usernumber}`;
    // const account = useraccount;
    const message = usermessage;
    sendTelegramMessage(name, number, message);
    setUsername("");
    setUsermessage("");
    setUsernumber("");
    // setUseraccount("");
    let chk = document.myform.poscheckbox 
    chk.checked = false  
  };
  
  function clrForm() {
    setUsername("");
    setUsernumber("");
    setUsermessage("");
    // setUseraccount("");
    let chk = document.myform.poscheckbox 
    chk.checked = false
}

function showPos() {
  let chk = document.myform.poscheckbox 
  let posHint = document.querySelector('.pos-hint-text')
  if (chk.checked === true) {
    // e.preventDefault(); 
    if (!navigator.geolocation) {
      alert("Brauzer geolokatsiyani qo'llab quvvatlamaydi");
    } else {
      navigator.geolocation.getCurrentPosition(showResult, showError);
    }
    myMapPos()
  
    function myMapPos() {
      function successCallback(pos) {
        const latitude = pos.coords.latitude;
        const longitude = pos.coords.longitude;
        const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
        setMyAdress(mapUrl)
      }
      function errorCallback() {
        alert("Joylashuvingiz aniqlanmadi!");
      }
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
   
    function showResult(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
    
      fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
        .then(response => response.json())
        .then(data => {
          const city = data.address.city;
          setCity(city)
          if (!city) {
              alert("The city name could not be determined for your location.");
          }
        })
        .catch(error => {
          console.error("An error occurred:", error);
        });
    } 
    function showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("You denied the request for geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }
    }
    posHint.innerHTML = 'Joylashuvingizni <br />tasdiqladingiz'
  }
  if (chk.checked === false) {
    setMyAdress('Tasdiqlanmadi')
    setCity('Tasdiqlanmadi')
    posHint.innerHTML = "Joylashuv <br />jo'natilmadi"
  }
}
  function closePopup() {
    document.querySelector('.popup').style.display = "none"
    document.querySelector('.popup-nosuccess').style.display = "none"
  }

  const [validationMessage, setValidationMessage] = useState('');
  const [phoneNumberDetails, setPhoneNumberDetails] = useState('');

  const handlePhoneNumber = (e) => {
    e.preventDefault(); 
    const phoneNumber = e.target.value;
    const pattern = /\d{9}/;
    if (phoneNumber.match(pattern) && phoneNumber.length == 9) {
      const formattedPhoneNumber = `+998 (${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5, 7)} ${phoneNumber.slice(7, 9)}`;
      setValidationMessage('Telefon raqam to\'g\'ri kiritildi.');
      setPhoneNumberDetails(' Raqamingiz: '+ formattedPhoneNumber);
    } else {
      setValidationMessage('Telefon raqam xato kiritildi.');
      setPhoneNumberDetails('');
    }
  };

return (
  <div className="Contact" id="contact">
    <div className="popup popup-success">
      <div className="top">
        <div className="left">
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
        </div>
        <div className="right">
          <h1>Xabaringiz yuborildi.</h1>
          <p>Yangiliklarni kuzatib borish uchun kanalimizga obuna bo'ling!</p>
        </div>
      </div>
      <div className="bottom">
        <a href="https://t.me/sifatedu">A'zo bo'lish</a>
        <button onClick={closePopup} className="btn-i-cancel">Orqaga</button>
      </div>
    </div>
    <div className="popup popup-nosuccess">
      <div className="top">
        <div className="left">
          <ion-icon name="close-circle-outline"></ion-icon>
        </div>
        <div className="right">
          <h1>Xabaringiz yuborildi.</h1>
          <p>Yangiliklarni kuzatib borish uchun kanalimizga obuna bo'ling!</p>
        </div>
      </div>
      <div className="bottom">
        <a href="https://t.me/sifatedu">A'zo bo'lish</a>
        <button onClick={closePopup} className="btn-i-cancel">Orqaga</button>
      </div>
    </div>
    <div className="con-main">
      <div className="left">
        <div className="con-img-box">
          <Link to="/">
            <img className="c-logo-dark" src={logodark} alt="Logo Dark" />
            <img className="c-logo-light" src={logolight} alt="Logo Light" />
          </Link>
        </div>
        <div className="sidebar">
          <h1>Biz bilan aloqa</h1>
          <p>
            Savol, murojaat va takliflaringizni yozib <br /> yuboring!
          </p>
          <p className="p2">Shuningdek bizning kontaktlarimiz: </p>
          <ul>
            <li>
              <a href="tel:+998883780808">
                <i className="fa-solid fa-phone"></i>
                <p>+998 (88) 378 0808</p>
              </a>
            </li>
            <li>
              <a href="https://t.me/sifatedu_admin">
                <i className="fa-brands fa-telegram"></i>
                <p>@sifatedu_admin</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="right">
        <form onSubmit={handleSubmit} name="myform">
          <div className="item">
            <label htmlFor="name">Ism va familiya</label>
            <input
              type="text"
              name="name"
              placeholder="F.I.O"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="item">
            <label htmlFor="phoneNumber">Telefon raqam</label>
            <div className="num-r-contact">
              <span>+998</span>
              <input
                id="phoneNumber"
                type="text"
                name="phoneNumber" 
                placeholder="00 000 0000" 
                value={usernumber}
                onChange={(e) => {
                  setUsernumber(e.target.value);
                  handlePhoneNumber(e);
                }}
                required
              />
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: "10px"}} className="phoneres">
              <p style={{fontSize: "12px", margin: "0"}} id="validationMessage">{validationMessage}</p>
              <p style={{fontSize: "12px", margin: "0"}} id="phoneNumberDetails">{phoneNumberDetails}</p>
            </div>
          </div>
          <div className="item">
            <label htmlFor="message">Xabar yozing</label>
            <textarea
              name="message"
              value={usermessage}
              onChange={(e) => setUsermessage(e.target.value)}
              required
              placeholder="Xabar"
            ></textarea>
          </div>
          <div style={{alignItems: "flex-end"}} className="item">
            <button className="btn-i-primary-light-hovered-2"><span className="checks"><input onClick={showPos} className="poschk" type="checkbox" name="poscheckbox" /></span><span className="pos-marks"><ion-icon name="location-outline"></ion-icon><p className="pos-hint-text">Joylashuvimni <br /> jo'natish</p></span></button>
          </div>
          <div className="item">
            <input 
              type="submit"
              className="btn-i-primary-dark-hovered"
              value="Yuborish"
            />
            <input onClick={clrForm} type="reset" value="Tozalash" />
          </div>
        </form>
      </div>
    </div>
  </div>
);
};

export default Contact;
