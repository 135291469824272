import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import clickup from '../Contents/images/clickup.png'
import payme from '../Contents/images/payme.png'
import bilgiuz from '../Contents/images/bilgiuz.png'
import cromsonweb from '../Contents/images/crimsonweb.png'
import itpark from '../Contents/images/itpark.png'

const handleDragStart = (e) => e.preventDefault();

const items = [
    <div className="part-item">
        <img src={clickup} />
        <p>Click up</p>
    </div>,
    <div className="part-item">
        <img src={payme} />
        <p>Payme</p>
    </div>,
    <div className="part-item">
        <img src={bilgiuz} />
        <p>Bilgi</p>
    </div>,
    <div className="part-item">
        <img src={cromsonweb} />
        <p>Crimson</p>
    </div>,
    <div className="part-item">
        <img src={itpark} />
        <p>IT Park</p>
    </div>
]
  
const responsive = {
    0: {items:0},
    576: {items:3},
    1000: { items: 5 },
    1400: { items: 6 },
  };

const Partners = () => {
  return (
    <div className='Partners' id='partners'>
        <div className="titlebox">
            <h1>Hamkorlarimiz</h1>
            {/* <p className='sp-bg-text'>Hamkorlarimiz</p> */}
        </div>
        <AliceCarousel 
            mouseTracking 
            items={items}
            autoHeight={true}
            autoWidth = {true}
            autoPlay= {true}
            responsive={responsive}
            animationDuration={5000}
            autoPlayInterval={1000}
            disableButtonsControls={true}
            disableDotsControls={true}
            infinite={true}
            touchTracking={false}
            
            

        />
    </div>
  )
}

export default Partners